import React from "react"
import { Helmet } from "react-helmet"
import StandardLayout from "../layout/StandardLayout";

export default () => (
  <StandardLayout>
    <Helmet>
      <title>Burke Shipping Services</title>
    </Helmet>

    <div
      className="c-hero"
      style={{ backgroundImage: "url(/images/shipping-hero-1.jpg)" }}
    ></div>

    <div className="u-fill-1 u-pad-large u-pad-xlarge@m">
      <div className="l-wrap u-width-5 u-align-center">
        <h1 className="c-heading +h1 u-marg-bottom">Burke Shipping Services</h1>
        <p className="u-font-large@m u-marg-bottom u-marg-bottom-large@m">
          A division of Fane Valley Co-operative, Burke Shipping Services Ltd.
          has been operating in the port of Londonderry for over 50 years. Burke
          Shipping Services Ltd. offer a complete range of services including
          port agency, stevedoring, warehousing, logistics and customs
          clearance.
        </p>
        <p className="u-font-large@m">
          Burke Shipping Services Ltd operates at the highest quality levels to
          ensure excellence in customer service.  Our Ship Agency, Stevedoring
          and Warehousing operations are all certified to ISO 9001: 2015
        </p>
      </div>
    </div>

    <div className="c-splitter"></div>
  </StandardLayout>
);